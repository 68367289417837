<div class="padding">

    <h3>VEHICLE SELECTION VIEW</h3>
    <p-divider align="center" type="dashed">
        <p-selectButton class="cmn-button-style" [options]="viewOptions" [(ngModel)]="selectedView" optionLabel="label"
            optionValue="value" (onChange)="basedOnModeofSearch($event)">
        </p-selectButton>
    </p-divider>

    <div *ngIf="selectedView == 'vdc'">
        <vma-vehicle-search-by-vdc (searchEvent)="searchVehicleEvent($event)"></vma-vehicle-search-by-vdc>
    </div>
    <div *ngIf="selectedView != 'vdc'">
        <vma-vehicle-search-by-model (searchEvent)="searchVehicleEvent($event)"></vma-vehicle-search-by-model>
    </div>

    <p-divider align="center" type="dashed">
        <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2" label="RESET"
            (click)="resetVehicleList()"></button>
        <button pButton type="button" icon="pi pi-search" class="cmn-button-style" label="SEARCH"
            (click)="searchAuditVehicles()"></button>
    </p-divider>
    <p-tabView *ngIf="showResult" styleClass="cmn-tabmenu-style" [scrollable]="true" (onChange)="onTabChange($event)"
        [(activeIndex)]="activeIndex">
        <p-tabPanel header="PENDING ELIGIBLE">
            <p-table #table1 [value]="fullVehList" styleClass="p-datatable-sm" [rows]="limit" selectionMode="single"
                [(selection)]="selectedPendingEligibleVehicle" responsiveLayout="scroll" [globalFilterFields]="['vin' ,'accessories',
                'modelCode','vdc','arrivalDate','serialNo','colorCd', 'workflowStatus' , 'actorName']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <button type="button" [disabled]="fullVehList.length == 0" pButton pRipple icon="pi pi-file-excel" (click)="exportPendingEligibleVehicleAsExcelReport()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <span class="p-input-icon-left p-ml-auto" style="margin-right: 20px; display: flex; justify-content: center; align-items: center;">
                            <label class="cmn-label-font-style" style="color: blueviolet; margin-right:10px;">Secondary Audits</label>
                            <p-inputSwitch [(ngModel)]="secondaryAuditFlag" (onChange) = "onSwitchChange($event)"></p-inputSwitch>
                            <!-- <h5 *ngIf="secondaryAuditFlag.active" class="toggle" style="margin-left: 10px; color: blue;">ON</h5>
                            <h5 *ngIf="!secondaryAuditFlag.active" class="toggle" style="margin-left: 10px;">OFF</h5> -->
                        </span>
                        <span class="p-input-icon-left">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table1)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 160px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 170px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="modelCode" scope="col">Model Code
                            <p-sortIcon field="modelCode"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 100px" pSortableColumn="vdc" scope="col">Vdc <p-sortIcon
                                field="vdc"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="arrivalDate" scope="col">Arrival
                            Date <p-sortIcon field="arrivalDate"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Category/Status Code</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                        <th class="cmn-table-header-style" style="width: 130px" pSortableColumn="workflowStatus" scope="col">Status
                            <p-sortIcon field="workflowStatus"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">User</th>
                    </tr>
                    <tr>
                        <th style="width: 160px" scope="col">
                            <div class="p-inputgroup">
                                <input class="cmn-table-data-style" minlength="4" [(ngModel)]="filteredVin" type="text"
                                    pInputText placeholder="Filter VIN" (input)="onClearVin(table1)">
                                <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
                                    (click)="onFilterVin(table1)"></button>
                            </div>                         
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th style="width: 160px" scope="col"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData"
                        [pSelectableRowDisabled]="rowData['workflowStatus'] === 'INPROGRESS' || rowData['workflowStatus'] === 'SECONDARY_INPROGRESS'">
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td *ngIf="secondaryAuditFlag" class="cmn-table-data-style">
                            <span *ngFor="let accessory of rowData.accessorList; let isLast = last">
                                <span [ngClass]="{ 'highlight': accessory.color }">{{accessory.componentCd}}</span><span *ngIf="!isLast"> | </span>
                            </span>
                        </td>
                        <td *ngIf="!secondaryAuditFlag" class="cmn-table-data-style">{{rowData.accessories}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.vdc}}</td>
                        <td class="cmn-table-data-style">{{rowData.arrivalDate}}</td>
                        <td class="cmn-table-data-style">{{rowData.nvsCatgCd}} {{rowData.nvsStatCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.serialNo}}</td>
                        <td class="cmn-table-data-style">{{rowData.colorCd}}</td>

                        <td class="cmn-table-data-style">
                            <span [class]="'workflow-status-badge workflow-status-' + rowData.workflowStatus">
                                {{rowData.workflowStatus}}
                            </span>
                        </td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="6" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>
            <div class="align-right page-action-panel-style">
                <p-button *ngIf = "secondaryAuditFlag && filteredSecondaryList.length > 0" label="READY FOR SECONDARY AUDIT" [disabled]="!isSecondaryAuditButtonEnabled()" 
                    styleClass="cmn-button-style cmn-button-style p-mr-2" (click)="onReadyForSecondaryAudit()">
                </p-button>
                <p-button label="UN SELECT" *ngIf="sharedService.displayUnSelectAuditButton"
                    [disabled]="!isUnselectButtonEnabled()"
                    styleClass="p-button-secondary cmn-button-style p-mr-2"
                    (click)="onUnSelectAudit(selectedPendingEligibleVehicle)">
                </p-button>
                <p-button label="SELECT VEHICLE" *ngIf="sharedService.displaySelectAuditButton"
                    [disabled]="!isSelectButtonEnabled()"
                    styleClass="cmn-button-style" (click)="selectVehicle()">
                </p-button>
            </div>
        </p-tabPanel>


        <p-tabPanel header="SELECTED">
            <p-table #table2 [value]="selectedAuditList" styleClass="p-datatable-sm" selectionMode="single"
                [(selection)]="selectedSelectedAuditRow" responsiveLayout="scroll" [globalFilterFields]="['vin' ,'accessories',
                'modelCode','vdc','arrivalDate','serialNo','colorCd', 'workflowStatus' , 'actorName']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <button type="button" [disabled]="selectedAuditList.length == 0" pButton pRipple icon="pi pi-file-excel" (click)="exportSelectedAuditVehicleAsExcelReport()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table2)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 160px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 170px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="modelCode" scope="col">Model Code
                            <p-sortIcon field="modelCode"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 100px" pSortableColumn="vdc" scope="col">Vdc <p-sortIcon
                                field="vdc"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="arrivalDate" scope="col">Arrival
                            Date <p-sortIcon field="arrivalDate"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Category/Status Code</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col" scope="col">Extr/Int Color</th>
                        <th class="cmn-table-header-style" style="width: 140px" scope="col">Selected By</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td class="cmn-table-data-style">{{rowData.accessories}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.vdc}}</td>
                        <td class="cmn-table-data-style">{{rowData.arrivalDate}}</td>
                        <td class="cmn-table-data-style">{{rowData.nvsCatgCd}} {{rowData.nvsStatCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.serialNo}}</td>
                        <td class="cmn-table-data-style">{{rowData.colorCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="selectedAuditList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>
            <div class="align-right page-action-panel-style">
                <p-button label="UN SELECT" *ngIf="sharedService.displayUnSelectAuditButton"
                    [disabled]="!selectedSelectedAuditRow" styleClass="p-button-secondary cmn-button-style p-mr-2"
                    (click)="onUnSelectAudit(selectedSelectedAuditRow)">
                </p-button>
                <p-button label="READY FOR AUDIT" *ngIf="sharedService.displayReadyForAuditButton"
                    [disabled]="!selectedSelectedAuditRow" styleClass="cmn-button-style" (click)="onReadyForAudit()">
                </p-button>
            </div>
        </p-tabPanel>

        <p-tabPanel header="INPROGRESS">
            <p-table #table3 [value]="inprogressAuditList" responsiveLayout="scroll" styleClass="p-datatable-sm"
                [globalFilterFields]="['vin' ,'accessories',
                'modelCode','vdc','arrivalDate','serialNo','colorCd', 'workflowStatus' , 'actorName']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table3)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 160px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 170px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="modelCode" scope="col">Model Code
                            <p-sortIcon field="modelCode"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 100px" pSortableColumn="vdc" scope="col">Vdc <p-sortIcon
                                field="vdc"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="arrivalDate" scope="col">Arrival
                            Date <p-sortIcon field="arrivalDate"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Category/Status Code</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                        <th class="cmn-table-header-style" style="width: 140px" scope="col">Assigned to</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td class="cmn-table-data-style">{{rowData.accessories}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.vdc}}</td>
                        <td class="cmn-table-data-style">{{rowData.arrivalDate}}</td>
                        <td class="cmn-table-data-style">{{rowData.nvsCatgCd}} {{rowData.nvsStatCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.serialNo}}</td>
                        <td class="cmn-table-data-style">{{rowData.colorCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="inprogressAuditList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>
        </p-tabPanel>

        <p-tabPanel header="SUBMITTED">
            <p-table #table4 [value]="submittedAuditList" responsiveLayout="scroll" styleClass="p-datatable-sm"
                selectionMode="single" [(selection)]="selectedSubmittedAuditRow" [globalFilterFields]="['vin' ,'accessories',
                'modelCode','vdc','arrivalDate','serialNo','colorCd', 'workflowStatus' , 'actorName']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto">
                            <em class="pi pi-search"></em>
                            <input class="cmn-input-font-style" pInputText type="text"
                                (input)="applyFilterGlobal($event, 'contains' , table4)" placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 160px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 170px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="modelCode" scope="col">Model Code
                            <p-sortIcon field="modelCode"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 100px" pSortableColumn="vdc" scope="col">Vdc <p-sortIcon
                                field="vdc"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="arrivalDate" scope="col">Arrival
                            Date <p-sortIcon field="arrivalDate"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Category/Status Code</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Serial No</th>
                        <th class="cmn-table-header-style" style="width: 120px" scope="col">Extr/Int Color</th>
                        <th class="cmn-table-header-style" style="width: 130px" pSortableColumn="workflowStatus" scope="col">Status
                            <p-sortIcon field="workflowStatus"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 140px" scope="col">User</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td class="cmn-table-data-style">{{rowData.accessories}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.vdc}}</td>
                        <td class="cmn-table-data-style">{{rowData.arrivalDate}}</td>
                        <td class="cmn-table-data-style">{{rowData.nvsCatgCd}} {{rowData.nvsStatCd}}</td>
                        <td class="cmn-table-data-style">{{rowData.serialNo}}</td>
                        <td class="cmn-table-data-style">{{rowData.colorCd}}</td>
                        <td class="cmn-table-data-style">
                            <span [class]="'workflow-status-badge workflow-status-' + rowData.workflowStatus">
                                {{rowData.workflowStatus}}
                            </span>
                        </td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="submittedAuditList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>

            <div class="align-right page-action-panel-style">
                <p-button label="GENERATE AUDIT REPORT" [disabled]="!selectedSubmittedAuditRow"
                    styleClass="cmn-button-style" (click)="onGenerateAuditReport()">
                </p-button>
            </div>
        </p-tabPanel>
    </p-tabView>
    <br>
</div>
