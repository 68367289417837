import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ALL, IndexedDBKey, WorkflowStatus } from 'src/app/common/app.constants';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { NPPSPropagationData } from 'src/app/core/model/ui/audit-admin.data.model';
import { AuditData } from 'src/app/core/model/ui/audit-data.model';
import { AuditVehicleListDetails, CountryOfOrigin, FinalAssemblyPoint } from 'src/app/core/model/ui/audit-vehicle.data.model';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';


@Component({
  selector: 'vma-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})
export class VehicleSelectionComponent implements OnInit {

  fullVehList: AuditVehicleListDetails[] = [];
  selectedAuditList: AuditVehicleListDetails[] = [];
  inprogressAuditList: AuditVehicleListDetails[] = [];
  submittedAuditList: AuditVehicleListDetails[] = [];

  selectedPendingEligibleVehicle: any;
  selectedSelectedAuditRow: any;
  selectedSubmittedAuditRow: any;
  selectedRowForNPPSSync: NPPSPropagationData = new NPPSPropagationData ();
  selectedRowForNVSSync: NPPSPropagationData = new NPPSPropagationData ();
  auditData: AuditData;
  viewOptions: any[];
  selectedView = "vdc";
  distributorCd = "91041";

  brandIdParams: string;
  yearNoParams: string;
  seriesIdParams: string;
  modelCodeIdParams: any;
  vdcValue: any;
  accessories: any;
  arrivaldate: boolean;
  searchType: string;

  finalAssemblyPointList: FinalAssemblyPoint[] = [];
  countryOfOriginList: CountryOfOrigin[] = [];

  offset = 0;
  limit = 100;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  currentWorkflowStatus: string = ALL;
  activeIndex = 0;
  filteredVin: string;
  showResult = false;
  count = 0;
  auditedVehicleEventId: number=0;

  secondaryList: any;
  filteredSecondaryList: any[] = [];
  secondaryAuditFlag: any = {};
  isSecondaryAuditCreated: boolean = false;

  constructor(
    private readonly vehicleService: VehicleService,
    public sharedService: SharedService,
    private readonly router: Router,
    private readonly exportExcelService: ExportExcelService,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) {
    this.viewOptions = [{ label: 'VDC View', value: 'vdc' }, { label: 'Model View', value: 'model' }];
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) &&
      !isNullOrUndefined(this.sharedService.vehicleSearchData.viewType)) {
      this.selectedView = this.sharedService.vehicleSearchData.viewType;
    }
    this.sharedService.isMandatoryField = true;
    this.sharedService.isMandatoryVdc = false;
    this.sharedService.isMandatorySeries = true;
    this.sharedService.setSecondaryAuditInputFlag(false);
  }

  onTabChange(e: any) {
    this.setWorkflowStatus(e.index);
    if (!isNullOrUndefined(this.brandIdParams)) {
      this.searchAuditVehicles();
    }
    this.count = 0;
  }

  applyFilterGlobal($event, stringVal, table: Table) {
    table.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onGenerateAuditReport(): void {
    this.sharedService.setAuditReportFlag(false);
    if (this.selectedSubmittedAuditRow) {
      this.sharedService.setVehAssmntEventId(this.selectedSubmittedAuditRow.vehAssmntEventId);
      this.navigateToAuditReport();
    }
  }

  navigateToAuditReport() {
    this.sharedService.setVehicleSelectionTabIndex(3);
    this.setSearchVehicleData();
    this.router.navigate(['audit/report']);
  }

  onReadyForAudit(): void {
    if (this.selectedSelectedAuditRow) {
      this.setReadytoAuditNVSData(this.selectedSelectedAuditRow);
      this.getFinalAssemblyPoints();
      this.getCountryOfOrigins();
      this.createAudit(this.selectedSelectedAuditRow.vehAssmntEventId);
    }
  }

  createAudit(vehAssmntEventId: number): void {
    this.vehicleService.addVehicle(vehAssmntEventId).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditedVehicleEventId = vehAssmntEventId;
        this.getAuditData();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  onUnSelectAudit(selectedRow: any): void {
    if (selectedRow) {
      this.setNPPSData(selectedRow);
      this.updateAuditWorkflow(selectedRow.vehAssmntEventId, WorkflowStatus.UN_SELECTED);
    }
  }

  exportPendingEligibleVehicleAsExcelReport() {
    this.exportExcelService.generateVehicleListExcel(this.fullVehList);
  }
  exportSelectedAuditVehicleAsExcelReport() {
    this.exportExcelService.generateVehicleListExcel(this.selectedAuditList);
  }

  getFinalAssemblyPoints(): void {
    this.vehicleService.getFinalAssemblyPoints().subscribe(res => {
      this.finalAssemblyPointList = [];
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.finalAssemblyPointList = res.data[0].data.finalAssemblyPoint;
        this.addFinalAssemblyPointsToIndexedDB();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  getAuditData(): void {
    let vehAssmntEventId: number;
    if (this.auditedVehicleEventId !== 0) {
      vehAssmntEventId = this.auditedVehicleEventId;
      this.auditedVehicleEventId = 0;
    } else {
      vehAssmntEventId = this.selectedSelectedAuditRow.vehAssmntEventId;
    }
    this.vehicleService.getAudit(vehAssmntEventId).subscribe(res => {
      this.auditData = new AuditData();
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditData = res.data[0].auditData;
        this.addAuditDataToIndexedDB();
        // this.nppsDataSync(this.selectedRowForNVSSync, vehAssmntEventId);
        this.confirmNavigateToInbox();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  addAuditDataToIndexedDB(): void {
    if(this.isSecondaryAuditCreated) {
      this.auditData.vehAssmntEventWorkflow.workflowStatusCd = WorkflowStatus.READY_FOR_SECONDARY_AUDIT;
    } else {
      this.auditData.vehAssmntEventWorkflow.workflowStatusCd = WorkflowStatus.READY_FOR_AUDIT;
    }
    this.indexedDBService.add(DbStores.VEH_ASSMNT_EVENT, this.auditData).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }

  updateAuditWorkflow(vehAssmntEventId: number, actionState: WorkflowStatus): void {
    const params: object = {
      action: actionState
    }
    const reqBody = {
      comment: null
    }
    this.vehicleService.updateWorkflow(vehAssmntEventId, reqBody, params).subscribe(res => {
      if (actionState === WorkflowStatus.READY) {
        this.confirmNavigateToInbox();
      } else {
        // this.nppsDataSync(this.selectedRowForNPPSSync, vehAssmntEventId);
        this.searchAuditVehicles();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  confirmNavigateToInbox(): void {
    this.confirmationService.confirm({
      message: 'Do you want to Stay on Same Screen or Go to Audit Inbox ?',
      acceptLabel: 'Go to Inbox',
      rejectLabel: 'Stay on same page',
      accept: () => {
        this.goToMyAuditInbox();
      }, reject: () => {
        this.stayOnSameScreen();
      }
    });
  }

  confirmReaudit(): void {
    this.confirmationService.confirm({
      message: 'Are you sure want to re-initiate the audit for already Submitted audit ?',
      accept: () => {
        this.selectAuditVehicle();
      }
    });
  }

  selectAuditVehicle() {
    this.vehicleService.selectAuditVehicle(this.selectedPendingEligibleVehicle.vin).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        // this.nppsDataSync(this.selectedRowForNPPSSync, res.data[0].data.vehAssmtEventId);
        this.searchAuditVehicles();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  goToMyAuditInbox(): void {
    this.sharedService.setVehicleSelectionTabIndex(1);
    if(this.secondaryAuditFlag) {
      this.sharedService.setAuditInboxTabIndex(3);
    }
    this.router.navigate(['audit/inbox']);
  }

  setSearchVehicleData() {
    const searchParams: any = {};
    searchParams.brandId = this.brandIdParams;
    searchParams.yearNo = this.yearNoParams;
    searchParams.seriesId = this.seriesIdParams;
    searchParams.modelCodeId = this.modelCodeIdParams;
    searchParams.vdcValue = this.vdcValue;
    searchParams.accessory = this.accessories;
    searchParams.arrivaldate = this.arrivaldate;
    searchParams.status = this.currentWorkflowStatus;
    searchParams.limit = this.limit;
    searchParams.offset = this.offset;
    searchParams.searchType = this.searchType;
    searchParams.viewType = this.selectedView;
    this.sharedService.vehicleSearchData = searchParams;
  }

  stayOnSameScreen(): void {
    this.searchAuditVehicles();
  }

  getCountryOfOrigins(): void {
    this.vehicleService.getCountryOfOrigins().subscribe(res => {
      this.countryOfOriginList = [];
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.countryOfOriginList = res.data[0].data.countryOfOrigin;
        this.addCountryOfOriginsToIndexedDB();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  addFinalAssemblyPointsToIndexedDB(): void {
    const params = {
      key: IndexedDBKey.FinalAssemblyPoints,
      value: this.finalAssemblyPointList
    }
    this.indexedDBService.update(DbStores.FINAL_ASSEMBLY_POINT, params).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  addCountryOfOriginsToIndexedDB(): void {
    const params = {
      key: IndexedDBKey.CountryOfOrigins,
      value: this.countryOfOriginList
    }
    this.indexedDBService.update(DbStores.COUNTRY_OF_ORIGIN, params).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }

  resetVehicleData() {
    this.limit = 100;
    this.offset = 0;
    this.fullVehList = [];
    this.selectedAuditList = [];
    this.inprogressAuditList = [];
    this.submittedAuditList = [];
    this.showPagination = false;
    this.sharedService.vehicleSearchData = null;
  }

  basedOnModeofSearch(event: any) {
    if (event.value === 'model') {
      this.searchType = 'MODELVIEW';
      this.sharedService.viewType = 'model';
    } else {
      this.searchType = 'VDCVIEW';
      this.sharedService.viewType = 'vdc';
    }
    this.resetVehicleData();
  }

  searchVehicleEvent(searchData: any) {
    if (!isNullOrUndefined(searchData)) {
      this.brandIdParams = searchData.brandId;
      this.yearNoParams = searchData.yearNo;
      this.seriesIdParams = searchData.seriesId;
      this.modelCodeIdParams = searchData.modelCodeId;
      this.vdcValue = searchData.vdcValue;
      this.accessories = searchData.accessory;
      this.arrivaldate = searchData.arrivaldate;
      if (isNullOrUndefined(this.sharedService.vehicleSearchData)) {
        this.resetVehicleData();
      } else {
        this.count++;
        const totalcount = this.setVehicleCount(this.sharedService.vehicleSearchData);
        this.backToVehicleSearch(totalcount);
      }
    }
  }

  setVehicleCount(searchData) {
    let searchCount = 0;
    if (!isNullOrUndefined(searchData.arrivaldate)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.brandId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.yearNo)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.seriesId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.modelCodeId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.vdcValue)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.accessory)) {
      searchCount++;
    }
    return searchCount;
  }

  backToVehicleSearch(totalcount) {
    if (this.count === totalcount) {
      this.currentWorkflowStatus = this.sharedService.vehicleSearchData.status;
      this.searchType = this.sharedService.vehicleSearchData.searchType;
      this.limit = this.sharedService.vehicleSearchData.limit;
      this.offset = this.sharedService.vehicleSearchData.offset;
      this.activeIndex = 3; // set only for last tab
      this.searchAuditVehicles();
    }
  }

  selectVehicle() {
    if (this.selectedPendingEligibleVehicle) {
      this.setNPPSData(this.selectedPendingEligibleVehicle);
      if (this.selectedPendingEligibleVehicle.workflowStatus === WorkflowStatus.UN_SELECTED) {
        this.updateAuditWorkflow(this.selectedPendingEligibleVehicle.vehAssmntEventId, WorkflowStatus.SELECTED);
      } else {
        if (this.selectedPendingEligibleVehicle.workflowStatus === WorkflowStatus.SUBMITTED) {
          this.confirmReaudit();
        } else {
          this.selectAuditVehicle();
        }
      }
    }
  }

  setNPPSData(rowData: any) {
    this.selectedRowForNPPSSync.modelYear = rowData.modelYear;
    this.selectedRowForNPPSSync.modelCode = rowData.modelCode;
    this.selectedRowForNPPSSync.vin = rowData.vin;
    if(!isNullOrUndefined(rowData.dummyVin)) {
      this.selectedRowForNPPSSync.dummyVin = rowData.dummyVin;
    } else {
      this.selectedRowForNPPSSync.dummyVin = rowData.vin;
    }
    this.selectedRowForNPPSSync.vdc = rowData.vdc;
    if(rowData.workflowStatus === WorkflowStatus.SELECTED) {
      this.selectedRowForNPPSSync.action = 'unselected_for_audit';
    } else {
      this.selectedRowForNPPSSync.action = 'selected_for_audit';
    }
  }

  setReadytoAuditNVSData(rowData: any) {
    this.selectedRowForNVSSync.modelYear = rowData.modelYear;
    this.selectedRowForNVSSync.modelCode = rowData.modelCode;
    this.selectedRowForNVSSync.vin = rowData.vin;
    this.selectedRowForNVSSync.dummyVin = rowData.dummyVin;
    this.selectedRowForNVSSync.vdc = rowData.vdc;
    this.selectedRowForNVSSync.action = 'in_progress_audit';
  }

  // nppsDataSync(data: NPPSPropagationData, vehAssmntEventId: number) {
  //   this.vehicleService.nppsDataPropagation(data, vehAssmntEventId).subscribe(res => {
  //     if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
  //       console.log("NPPS synced");
  //       this.messageService.add({ severity: 'info', summary: '', detail: "Data synced with NPPS and NVS.", life: 5000  });
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     this.sharedService.handleHttpError(error);
  //   })
  // }

  setWorkflowStatus(tabIndex: number) {
    this.currentWorkflowStatus = ALL;
    if (tabIndex === 1) {
      this.currentWorkflowStatus = WorkflowStatus.SELECTED;
    } else if (tabIndex === 2) {
      this.currentWorkflowStatus = `${WorkflowStatus.INPROGRESS},${WorkflowStatus.SECONADRY_INPROGRESS}`;
    } else if (tabIndex === 3) {
      this.currentWorkflowStatus = `${WorkflowStatus.SUBMITTED},${WorkflowStatus.DRC_REVIEWED},${WorkflowStatus.SUBMITTED_SECONDARY_AUDIT}`;
    }
  }

  searchAuditVehicles() {
    this.sharedService.clearPreviousMessages();
    this.secondaryAuditFlag = false;
    this.sharedService.vehicleSearchData = null;
    this.selectedPendingEligibleVehicle = null;
    this.selectedSelectedAuditRow = null;
    this.selectedSubmittedAuditRow = null;
    if (this.searchType === "MODELVIEW") {
      this.searchModelData();
    } else {
      this.searchVdcData();
    }
  }

  searchModelData() {
    if (!isNullOrUndefined(this.brandIdParams) && !isNullOrUndefined(this.brandIdParams['id'])
      && !isNullOrUndefined(this.yearNoParams) && !isNullOrUndefined(this.seriesIdParams)
      && !isNullOrUndefined(this.seriesIdParams['id']) && !isNullOrUndefined(this.modelCodeIdParams)
      && !isEmpty(this.modelCodeIdParams) && !isNullOrUndefined(this.modelCodeIdParams.map(x => x['name']).join(','))) {
      const params: any = {};
      params.brand = this.brandIdParams['id'];
      params.modelyear = this.yearNoParams;
      params.series = this.seriesIdParams['id'];
      params.modelcd = this.modelCodeIdParams.map(x => x['name']).join(',');
      params.arrivaldate = this.arrivaldate;
      params.status = this.currentWorkflowStatus;
      params.limit = this.limit;
      params.offset = this.offset;
      if (this.filteredVin !== "" && this.filteredVin !== undefined) {
        params.vin = this.filteredVin;
      }
      if (!isNullOrUndefined(this.vdcValue) && !isEmpty(this.vdcValue)) {
        params.vdccd = this.vdcValue.map(x => x['code']).join(',');
      }
      if (!isNullOrUndefined(this.accessories) && !isEmpty(this.accessories)) {
        params.accessories = this.accessories.map(x => x['code']).join(',');
      }
      this.getSearchVehicleDetails(params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  searchVdcData() {
    if (!isNullOrUndefined(this.brandIdParams) && !isNullOrUndefined(this.brandIdParams['id'])
      && !isNullOrUndefined(this.yearNoParams) && !isNullOrUndefined(this.seriesIdParams)
      && !isNullOrUndefined(this.seriesIdParams['id']) && !isNullOrUndefined(this.vdcValue)
      && !isNullOrUndefined(this.vdcValue['code'])) {
      const params: any = {};
      params.brand = this.brandIdParams['id'];
      params.modelyear = this.yearNoParams;
      params.series = this.seriesIdParams['id'];
      params.vdccd = this.vdcValue['code'];
      params.arrivaldate = this.arrivaldate;
      params.status = this.currentWorkflowStatus;
      params.limit = this.limit;
      params.offset = this.offset;
      if (this.filteredVin !== "" && this.filteredVin !== undefined) {
        params.vin = this.filteredVin;
      }
      if (!isNullOrUndefined(this.modelCodeIdParams) && !isEmpty(this.modelCodeIdParams)) {
        params.modelcd = this.modelCodeIdParams.map(x => x['code']).join(',');
      }
      if (!isNullOrUndefined(this.accessories) && !isEmpty(this.accessories)) {
        params.accessories = this.accessories.map(x => x['code']).join(',');
      }
      this.getSearchVehicleDetails(params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  onClearVin(table1: Table) {
    if (this.filteredVin === '') {
      table1.filter(this.filteredVin, 'vin', 'contains');
    }
  }

  onFilterVin(table1: Table) {
    if (this.filteredVin.length === 17) {
      const filteredRes = this.fullVehList.filter(value => value.vin.startsWith(this.filteredVin));
      if (isEmpty(filteredRes)) {
        this.searchAuditVehicles();
      } else {
        table1.filter(this.filteredVin, 'vin', 'contains');
      }
    } else {
      this.searchAuditVehicles();
    }
  }

  getSearchVehicleDetails(params: any) {
    this.vehicleService.getAuditVehicleList(this.distributorCd, params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.setVehicleResp(res.data[0].auditVehicle.vehicleList);
        if (!isNullOrUndefined(res.data[0].auditVehicle.total)) {
          this.totalRecords = res.data[0].auditVehicle.total;
          this.offset = res.data[0].auditVehicle.offset;
          this.setPaginationData();
          this.showPagination = true;
        } else {
          this.sharedService.handledNoRecords();
          this.resetVehicleData();
        }
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    });
  }

  filterSecondaryAuditRecords() {
    let filteredRecords = this.fullVehList.filter(item => item.workflowStatus === null).map(item => item.vehdocId);
    return filteredRecords;
  }

  setVehicleResp(apiResp: any) {
    this.showResult = true;
    if (this.currentWorkflowStatus === ALL) {
      this.fullVehList = apiResp;
    } else if (this.currentWorkflowStatus === WorkflowStatus.SELECTED) {
      this.selectedAuditList = apiResp;
    } else if (this.currentWorkflowStatus === `${WorkflowStatus.INPROGRESS},${WorkflowStatus.SECONADRY_INPROGRESS}`) {
      this.inprogressAuditList = apiResp;
    } else if (this.currentWorkflowStatus === `${WorkflowStatus.SUBMITTED},${WorkflowStatus.DRC_REVIEWED},${WorkflowStatus.SUBMITTED_SECONDARY_AUDIT}`) {
      this.submittedAuditList = apiResp;
    }
  }

  setPaginationData() {
    this.totalPages = Math.ceil(this.totalRecords / this.limit);
    if (this.totalPages > 5) {
      this.totalPages = 5;
    }
  }

  onPaginator(event: any) {
    this.offset = event.first;
    this.limit = event.rows;
    this.searchAuditVehicles();
  }

  resetVehicleList() {
    this.showResult = false;
    this.sharedService.clearButton = true;
  }

  onSwitchChange(event: any) {
    if(event.checked){
      this.selectedPendingEligibleVehicle = null;
      const secondaryAuditRecords = this.filterSecondaryAuditRecords();
      this.secondaryAuditData(secondaryAuditRecords);
    } else {
      this.searchAuditVehicles();
      this.filteredSecondaryList = [];
    }
  }

  secondaryAuditData(vehDocIds: Array<any>): void {
    const params: any = {};
    params.vehDocIdData = vehDocIds.join(',');
    params.modelyear = this.yearNoParams;
    if (!isNullOrUndefined(this.modelCodeIdParams) && !isEmpty(this.modelCodeIdParams)) {
      params.modelCode = this.modelCodeIdParams.map(x => x['name']).join(',');
 
      if (!isNullOrUndefined(vehDocIds) && !isEmpty(vehDocIds)) {
        this.vehicleService.secondaryAuditVehicleList(params).subscribe(res => {
          if (!isNullOrUndefined(res.data[0].secondaryAuditRecordsList) && !isEmpty(res.data[0].secondaryAuditRecordsList)) {
            this.secondaryList = res.data[0].secondaryAuditRecordsList;
            this.listSecondaryAudits(this.secondaryList);
          } else {
            this.sharedService.handledNoRecords();
            this.fullVehList = [];
          }
        }, (error: HttpErrorResponse) => {
          this.sharedService.handleHttpError(error);
        })
      } else {
        this.messageService.add({ severity: 'info', summary: '', detail: "No records found!", life: 1000 });
      }
    } else {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'ERROR',
          detail: 'Please select model code(s) to proceed.',
          sticky: true
        }
      );
      setTimeout(() => {
        this.secondaryAuditFlag = false;
      }, 10);
    }
  }

  listSecondaryAudits(secondaryAuditList: any) {
    for(const secondaryItem of secondaryAuditList) {
      const matchedItem = this.fullVehList.find(vehListItem => vehListItem.vehdocId === secondaryItem.vehDocIdData);
      if(matchedItem) {
        const matchedAccessories = matchedItem.accessorList.filter(accessory => secondaryItem.accessories.includes(accessory.componentCd));
        if(matchedAccessories.length > 0) {
          this.filteredSecondaryList.push(matchedItem);
        }
      }
    }
    this.fullVehList = this.filteredSecondaryList;
    this.updateAccyColor();
  }

  onReadyForSecondaryAudit() {
    if (this.selectedPendingEligibleVehicle) {
      this.setReadytoAuditNVSData(this.selectedPendingEligibleVehicle);
      this.vehicleService.selectAuditVehicle(this.selectedPendingEligibleVehicle.vin).subscribe((res: any) => {
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const newVehAssmntEventId = res.data[0].data.vehAssmtEventId;
          this.getFinalAssemblyPoints();
          this.getCountryOfOrigins();
          this.createSecondaryAudit(newVehAssmntEventId);
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      })
    }
  }

  createSecondaryAudit(vehAssmntEventId: number): void {
    const vehicle = this.secondaryList.find(item => item.vehDocIdData === this.selectedPendingEligibleVehicle.vehdocId);
    let param: any ={};
    if (vehicle) {
      const accessories = vehicle.accessories.join(',');
      param = {
        accessories: accessories
      }
    }
    this.vehicleService.secondaryAuditService(vehAssmntEventId, param).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.isSecondaryAuditCreated = true;
        this.auditedVehicleEventId = vehAssmntEventId;
        this.getAuditData();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  isSelectButtonEnabled(): boolean {
    return this.selectedPendingEligibleVehicle && !this.secondaryAuditFlag && (this.selectedPendingEligibleVehicle.workflowStatus === 'UN_SELECTED' 
    || this.selectedPendingEligibleVehicle.workflowStatus === 'SUBMITTED' || !this.selectedPendingEligibleVehicle.workflowStatus);
  }

  isUnselectButtonEnabled(): boolean {
    return this.selectedPendingEligibleVehicle && this.selectedPendingEligibleVehicle.workflowStatus === 'SELECTED';
  }

  isSecondaryAuditButtonEnabled(): boolean {
    return this.selectedPendingEligibleVehicle && !this.selectedPendingEligibleVehicle.workflowStatus;
  }

  updateAccyColor(): void {
    this.fullVehList.forEach(fullVeh => {
      let veh = this.secondaryList.find(v => v.vehDocIdData === fullVeh.vehdocId);
      if(veh) {
        fullVeh.accessorList.forEach(accessory => {
          accessory['color'] = veh.accessories.includes(accessory.componentCd) && accessory.componentTypeCd === 'F';
        });
        fullVeh.accessories = fullVeh.accessorList.map(accessory => accessory.componentCd).join(' | ');
      }
    });
  }

}

